import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {updateEmissions} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {PLEASE_SELECT} from "../../../utils/constants";


class Emissions extends React.Component {

    handleChange = (e) => {
        const newEmission = e.currentTarget.value;
        this.props.updateEmissions(newEmission);
    };

    render() {

        if (this.props.condition === null) {
            return <></>
        }

        const emissions = this.props.condition.emissions;

        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                <DataPair label={"Emissions"} required>
                    <Dropdown
                        id={"emissions"}
                        value={emissions}
                        onChange={this.handleChange}>
                        {!emissions && <option value='' disabled>{PLEASE_SELECT}</option>}
                        {this.props.staticOptionValues && this.props.staticOptionValues.emissions.map(option => {
                            return (
                                <option
                                    key={option.description}
                                    value={option.description}>{option.description}
                                </option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Col>
        )
    }
}

const mapDispatchToProps = {
    updateEmissions
};

function mapStateToProps({condition, staticOptionValues}) {
    return {condition, staticOptionValues};
}

export default connect(mapStateToProps, mapDispatchToProps)(Emissions);