export const LOAD_WORK_ORDER_SUCCESS = "LOAD_WORK_ORDER_SUCCESS";
export const DISABLE_ALL_BUTTONS = "DISABLE_ALL_BUTTONS";

/* Application Flags */
export const SET_APPLICATION_FLAGS = 'SET_APPLICATION_FLAGS';

/* Announcements */
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const ADD_ANNOUNCEMENT_BY_TYPE = "ADD_ANNOUNCEMENT_BY_TYPE";
export const ADD_ANNOUNCEMENT_FOR_DAMAGE = "ADD_ANNOUNCEMENT_FOR_DAMAGE";
export const DELETE_ANNOUNCEMENT_BY_CODE = "DELETE_ANNOUNCEMENT_BY_CODE";
export const DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE = "DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE"
export const UPDATE_ANNOUNCEMENTS = "UPDATE_ANNOUNCEMENTS";

/* Consignment */
export const UNLOAD_WORK_ORDER = "UNLOAD_WORK_ORDER";
export const UPDATE_CONSIGNMENT = "UPDATE_CONSIGNMENT";

/* Data Lists */
export const FETCH_ANNOUNCEMENTS_DICTIONARY_SUCCESS = "FETCH_ANNOUNCEMENTS_DICTIONARY_SUCCESS";
export const FETCH_ANNOUNCEMENTS_DICTIONARY_ERROR = "FETCH_ANNOUNCEMENTS_DICTIONARY_ERROR";

/* Locations */
export const LOAD_AUCTION_LOCATIONS = "LOAD_AUCTION_LOCATIONS";
export const LOAD_LOCATIONS_FAILURE = "LOAD_LOCATIONS_FAILURE";

/* Push Panel */
export const HIDE_ALL_PUSH_PANELS = 'HIDE_ALL_PUSH_PANELS';
export const SHOW_PUSH_PANEL = 'SHOW_PUSH_PANEL';

/* Accessories */
export const UPDATE_KEY_VALUE = "UPDATE_KEY_VALUE";
export const UPDATE_HEADSET_COUNT = "UPDATE_HEADSET_COUNT";
export const UPDATE_SERVICE_REQUIRED = "UPDATE_SERVICE_REQUIRED";
export const UPDATE_REMOTE_COUNT = "UPDATE_REMOTE_COUNT";
export const UPDATE_DOCUMENTATION = "UPDATE_DOCUMENTATION";
export const UPDATE_PAINT_TYPE = "UPDATE_PAINT_TYPE";
export const UPDATE_CHARGING_CABLE = "UPDATE_CHARGING_CABLE";

/* Client Specifics */
export const ADD_BMW_REP_NOTE = "ADD_BMW_REP_NOTE";
export const DELETE_BMW_REP_NOTE = "DELETE_BMW_REP_NOTE";

/* Condition */
export const UPDATE_CONDITION = "UPDATE_CONDITION";
export const UPDATE_INTERIOR_ODOR = "UPDATE_INTERIOR_ODOR";
export const UPDATE_LAST_SERVICE_DATE = "UPDATE_LAST_SERVICE_DATE";
export const UPDATE_LAST_SERVICE_ODO = "UPDATE_LAST_SERVICE_ODO";
export const UPDATE_DRIVABLE = "UPDATE_DRIVABLE";
export const UPDATE_ENGINE_STARTS = "UPDATE_ENGINE_STARTS";
export const UPDATE_SERVICE_EXCEEDED = "UPDATE_SERVICE_EXCEEDED";
export const UPDATE_EMISSIONS = "UPDATE_EMISSIONS";
export const UPDATE_SERVICE_DUE = "UPDATE_SERVICE_DUE";
export const UPDATE_FUEL_LEVEL = "UPDATE_FUEL_LEVEL";
export const UPDATE_FUEL_LEVEL_DAMAGE = "UPDATE_FUEL_LEVEL_DAMAGE";
export const UPDATE_SERVICE_NOTES = "UPDATE_SERVICE_NOTES";
export const UPDATE_ODOMETER_OPERABLE = "UPDATE_ODOMETER_OPERABLE";
export const UPDATE_CHARGE_PERCENTAGE = "UPDATE_CHARGE_PERCENTAGE";
export const UPDATE_CURRENT_RANGE = "UPDATE_CURRENT_RANGE";
export const SET_CONDITION_ODOMETER_FROM_UNIT = "SET_CONDITION_ODOMETER_FROM_UNIT";
export const SET_CONDITION_FROM_REPURPOSED = "SET_CONDITION_FROM_REPURPOSED";
export const SET_REPURPOSED_FLAG = "SET_REPURPOSED_FLAG";

/* Description */
export const UPDATE_DESCRIPTION_FUEL_LEVEL = "UPDATE_DESCRIPTION_FUEL_LEVEL";
export const UPDATE_FLOOD_DAMAGE = "UPDATE_FLOOD_DAMAGE";
export const UPDATE_FRAME_DAMAGE = "UPDATE_FRAME_DAMAGE";
export const UPDATE_STRUCTURE_ALTERATION = "UPDATE_STRUCTURE_ALTERATION";

/* Designated Description */
export const UPDATE_INSTALLED_COLOR = "UPDATE_INSTALLED_COLOR"
export const UPDATE_SEAT_TRIM = "UPDATE_SEAT_TRIM"
export const SET_DESIGNATED_DESCRIPTION = "SET_DESIGNATED_DESCRIPTION"
export const SET_DESIGNATED_DESCRIPTION_FROM_VCR = "SET_DESIGNATED_DESCRIPTION_FROM_VCR"
export const RE_DECODE_VIN = "RE_DECODE_VIN";
export const UPDATE_WHEELS = "UPDATE_WHEELS";
export const UPDATE_ROOF_TYPE = "UPDATE_ROOF_TYPE";
export const UPDATE_YEAR = "UPDATE_YEAR";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_BODY_STYLE = "UPDATE_BODY_STYLE";
export const UPDATE_MAKE = "UPDATE_MAKE";
export const UPDATE_MODEL = "UPDATE_MODEL";
export const UPDATE_TRIM = "UPDATE_TRIM";
export const UPDATE_FUEL_TYPE = "UPDATE_FUEL_TYPE";
export const UPDATE_CYLINDER_COUNT = "UPDATE_CYLINDER_COUNT";
export const UPDATE_BLOCK_TYPE = "UPDATE_BLOCK_TYPE";
export const UPDATE_DISPLACEMENT = "UPDATE_DISPLACEMENT";
export const UPDATE_INDUCTION = "UPDATE_INDUCTION";
export const UPDATE_TRANSMISSION = "UPDATE_TRANSMISSION";
export const UPDATE_DRIVETRAIN = "UPDATE_DRIVETRAIN";
export const UPDATE_SUB_SERIES = "UPDATE_SUB_SERIES";

export const SHOW_TRIM_FINDER = "SHOW_TRIM_FINDER";
export const HIDE_TRIM_FINDER = "HIDE_TRIM_FINDER";

/* User details */
export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const UPDATE_TIRE = "UPDATE_TIRE";
export const ADD_DAMAGE = "ADD_DAMAGE";
export const ADD_AUTOMATED_DAMAGE = "ADD_AUTOMATED_DAMAGE";
export const DELETE_DAMAGE = "DELETE_DAMAGE";
export const DELETE_AUTOMATED_DAMAGE = "DELETE_AUTOMATED_DAMAGE";
export const UPDATE_DAMAGE = "UPDATE_DAMAGE";
export const SAVE_INCOMPLETE_DAMAGE = "SAVE_INCOMPLETE_DAMAGE";
export const CLEAR_INCOMPLETE_DAMAGE = "CLEAR_INCOMPLETE_DAMAGE";

/* Global Display */
export const HIDE_DAMAGE_SUCCESS_MESSAGE = "HIDE_DAMAGE_SUCCESS_MESSAGE"
export const SHOW_DAMAGE_SUCCESS_MESSAGE = "SHOW_DAMAGE_SUCCESS_MESSAGE"
export const CLEAR_VEHICLE_DATA = "CLEAR_VEHICLE_DATA";
export const FLOOD_DAMAGE_SELECTED = "FLOOD_DAMAGE_SELECTED";
export const STRUCTURAL_DAMAGE_SELECTED = "STRUCTURAL_DAMAGE_SELECTED";
export const STRUCTURAL_ALTERATION_SELECTED = "STRUCTURAL_ALTERATION_SELECTED";
export const TOGGLE_ENGINE_OIL_SERVICE_SELECTED = "TOGGLE_ENGINE_OIL_SERVICE_SELECTED";
export const TOGGLE_BRAKE_FLUID_SERVICE_SELECTED = "TOGGLE_BRAKE_FLUID_SERVICE_SELECTED";
export const SHOW_ADD_EDIT_DAMAGE = "SHOW_ADD_EDIT_DAMAGE";
export const SHOW_LOGOUT_MODAL = "SHOW_LOGOUT_MODAL";
export const HIDE_LOGOUT_MODAL = "HIDE_LOGOUT_MODAL";
export const HIDE_PROFILE_MODAL_INSIDE_WO = "HIDE_PROFILE_MODAL_INSIDE_WO";
export const HIDE_REDECODE_MODAL = "HIDE_REDECODE_MODAL";
export const SHOW_REDECODE_MODAL = "SHOW_REDECODE_MODAL";
export const SHOW_LOCKED_RECORD_MODAL = "SHOW_LOCKED_RECORD_MODAL";
export const HIDE_LOCKED_RECORD_MODAL = "HIDE_LOCKED_RECORD_MODAL";
export const SHOW_DAMAGE_LIST = "SHOW_DAMAGE_LIST";
export const HIDE_WORK_ORDER_ERROR_MODAL = "HIDE_VEHICLE_ERROR_MODAL";
export const HIDE_AUDIT_EXISTS_MODAL = "HIDE_AUDIT_EXISTS_MODAL";
export const SHOW_AUDIT_EXISTS_MODAL = "SHOW_AUDIT_EXISTS_MODAL";
export const SHOW_BUTTON_TRAY = "SHOW_BUTTON_TRAY";
export const HIDE_BUTTON_TRAY = "HIDE_BUTTON_TRAY";
export const TOGGLE_DAMAGE_MODAL = "TOGGLE_DAMAGE_MODAL";
export const SHOW_CLOSE_INSPECTION_CONFIRMATION = "SHOW_CLOSE_INSPECTION_CONFIRMATION";
export const HIDE_CLOSE_INSPECTION_CONFIRMATION = "HIDE_CLOSE_INSPECTION_CONFIRMATION";
export const WRAP_VEHICLE_COLOR_NOT_SELECTED = "WRAP_VEHICLE_COLOR_NOT_SELECTED";
export const SHOW_CANCEL_BUTTON_FOR_LOAD = "SHOW_CANCEL_BUTTON_FOR_LOAD";
export const HIDE_CANCEL_BUTTON_FOR_LOAD = "HIDE_CANCEL_BUTTON_FOR_LOAD";
export const UPDATE_VEHICLE_IS_SOLD = "UPDATE_VEHICLE_IS_SOLD";
export const SHOW_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL = "SHOW_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL";
export const HIDE_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL = "HIDE_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL";
export const SHOW_DELETE_INCOMPLETE_INSPECTION_MODAL = "SHOW_DELETE_INCOMPLETE_INSPECTION_MODAL";
export const HIDE_DELETE_INCOMPLETE_INSPECTION_MODAL = "HIDE_DELETE_INCOMPLETE_INSPECTION_MODAL";
export const SHOW_DAMAGE_SUMMARY_VIEW = "SHOW_DAMAGE_SUMMARY_VIEW";
export const SHOW_ADD_DAMAGE_VIEW = "SHOW_ADD_DAMAGE_VIEW";
export const SHOW_ADD_DAMAGE_PANEL = "SHOW_ADD_DAMAGE_PANEL";
export const SHOW_SUGGESTED_DAMAGES_PANEL = "SHOW_SUGGESTED_DAMAGES_PANEL";

/* Flat Car */
export const CLEAR_SELECTED_FLAT_CAR_PANEL = 'CLEAR_SELECTED_FLAT_CAR_PANEL';
export const SET_CLEAR_PANEL_ON = 'SET_CLEAR_PANEL_ON'
export const SET_CLEAR_PANEL_OFF = 'SET_CLEAR_PANEL_OFF'
export const GET_ITEMS_FOR_PANEL_SUCCESS = 'GET_ITEMS_FOR_PANEL_SUCCESS';
export const GET_ITEMS_FOR_PANEL_FAILURE = 'GET_ITEMS_FOR_PANEL_FAILURE';
export const SET_SELECTED_FLAT_CAR_PANEL = 'SET_SELECTED_FLAT_CAR_PANEL';
export const HIDE_FLAT_CAR_PANEL = 'HIDE_FLAT_CAR_PANEL';
export const SHOW_FLAT_CAR_PANEL = 'SHOW_FLAT_CAR_PANEL';
export const SHOW_CURRENT_FLAT_CAR_VIEW = 'SHOW_CURRENT_FLAT_CAR_VIEW';
export const SHOW_EXTERIOR_FLAT_CAR_VIEW = "SHOW_EXTERIOR_FLAT_CAR_VIEW";
export const SHOW_INTERIOR_FLAT_CAR_VIEW = "SHOW_INTERIOR_FLAT_CAR_VIEW";
export const SHOW_MECHANICAL_FLAT_CAR_VIEW = "SHOW_MECHANICAL_FLAT_CAR_VIEW";
export const SHOW_STRUCTURAL_FLAT_CAR_VIEW = "SHOW_STRUCTURAL_FLAT_CAR_VIEW";

/* Notes and Comments */
export const ADD_F5_NOTE = "ADD_F5_NOTE";
export const DELETE_F5_NOTE = "DELETE_F5_NOTE";
export const POPULATE_F5_NOTES = "POPULATE_F5_NOTES";

/* Work Order */
export const LOADING_WORK_ORDER = 'LOADING_WORK_ORDER';
export const LOADING_WORK_ORDER_COMPLETE = 'LOADING_WORK_ORDER_COMPLETE';
export const LOAD_WORK_ORDER_FAIL = 'LOAD_WORK_ORDER_FAIL';
export const SHOW_WORK_ORDER_ERROR_MODAL = 'SHOW_WORK_ORDER_ERROR_MODAL';
export const SUBMIT_WORK_ORDER_SUCCESS = "SUBMIT_WORK_ORDER_SUCCESS";
export const SUBMIT_WORK_ORDER_PROGRESS = "SUBMIT_WORK_ORDER_PROGRESS";
export const SUBMIT_WORK_ORDER_ERROR = "SUBMIT_WORK_ORDER_ERROR";
export const RESET_SUBMIT_STATUS = "RESET_SUBMIT_STATUS";

/* Damage Images */
export const DELETE_DAMAGE_IMAGE = 'DELETE_DAMAGE_IMAGE';
export const SET_DAMAGE_IMAGES = 'SET_DAMAGE_IMAGES';
export const ADD_SUGGESTED_DAMAGE_IMAGE = 'ADD_SUGGESTED_DAMAGE_IMAGE';

/* Miscellaneous Images */
export const ADD_MISCELLANEOUS_IMAGE = "ADD_MISCELLANEOUS_IMAGES";
export const DELETE_MISCELLANEOUS_IMAGE = "DELETE_MISCELLANEOUS_IMAGE";

/* Status Message */
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const CLEAR_ALL_MESSAGES = 'CLEAR_ALL_MESSAGES';
export const UPDATE_DAMAGE_IMAGE = 'UPDATE_DAMAGE_IMAGE';
export const HANDLE_KEEP_PHOTO_RESPONSE = 'HANDLE_KEEP_PHOTO_RESPONSE';

/* Scan */
export const SET_SCAN_AUCTION_CODE = 'SET_SCAN_AUCTION_CODE';
export const SET_AUCTION_CODE = 'SET_AUCTION_CODE';
export const SET_WORK_ORDER_INFO = 'SET_WORK_ORDER_INFO';

/* Work List */
export const GET_WORK_LIST_REQUESTED = "GET_WORK_LIST_REQUESTED";
export const GET_WORK_LIST_SUCCESS = "GET_WORK_LIST_SUCCESS";

/* Unit */
export const UPDATE_ODOMETER_READING = "UPDATE_ODOMETER_READING";
export const UPDATE_ODOMETER_UNITS = "UPDATE_ODOMETER_UNITS";

/* Suggested Damages */
export const ADD_SUGGESTED_DAMAGE = "ADD_SUGGESTED_DAMAGE"
export const DELETE_SUGGESTED_DAMAGE = "DELETE_SUGGESTED_DAMAGE";
export const MANUALLY_ADD_SUGGESTED_DAMAGE = "MANUALLY_ADDED_SUGGESTED_DAMAGE";

export const UPDATE_ODOMETER_CONFIRMED = "UPDATE_ODOMETER_CONFIRMED";
export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const SET_USE_ODOMETER_READING_FROM_UNIT = "SET_USE_ODOMETER_READING_FROM_UNIT";