import {
    ADD_AUTOMATED_DAMAGE,
    DELETE_AUTOMATED_DAMAGE,
    LOAD_WORK_ORDER_SUCCESS,
    SET_CONDITION_FROM_REPURPOSED,
    UNLOAD_WORK_ORDER
} from "../actions/dispatchTypes";
import {BMW_GROUP_CODES, KEY_TYPES, MBC_GROUP_CODES} from "../utils/constants";
import {AUTOMATICALLY_CREATED_DAMAGES, SERVICE_REQUIRED_DAMAGE} from "../utils/constantsDamages";
import {calculateDamageKey} from "./conditionReducer";

export default function automatedDamagesReducer(state = [], action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
        case SET_CONDITION_FROM_REPURPOSED:
            return getAutomatedDamagesFromCondition(state, action.payload);
        case UNLOAD_WORK_ORDER:
            return [];
        case ADD_AUTOMATED_DAMAGE:
            return addAutomatedDamageToList(state, action.payload);
        case DELETE_AUTOMATED_DAMAGE:
            return removeAutomatedDamageFromList(state, action.payload);
        default:
            return state;
    }
}

function addAutomatedDamageToList(state, damage) {
    damage.damageKey = calculateDamageKey(damage)
    const updatedState = [...state];
    updatedState.push(damage);
    return updatedState;
}

function removeAutomatedDamageFromList(state, damage) {
    return state.filter(orgDamage => orgDamage.item !== damage.item)
}

function getAutomatedDamagesFromCondition(state, payload) {
    let completeAutoDamageList = [];
    if (!payload.condition || !payload.condition.damages) {
        return state;
    } else {
        const nonKeyAutoDamages = getNonKeyAutoDamages(payload)
        const requiredKeyDamages = getRequiredKeyAutoDamages(payload)
        const zeroKeyDamages = getZeroKeysAutoDamages(payload, requiredKeyDamages)
        const autoDamageList = AUTOMATICALLY_CREATED_DAMAGES.concat(nonKeyAutoDamages)
            .concat(requiredKeyDamages)
            .concat(zeroKeyDamages);
        if (BMW_GROUP_CODES.includes(payload.account.groupCode) || MBC_GROUP_CODES.includes(payload.account.groupCode)) {
            autoDamageList.push(SERVICE_REQUIRED_DAMAGE)
        }
        autoDamageList.forEach(autoDamage => {
            const autoDamageKey = `L1-${autoDamage.itemCode}-${autoDamage.subItemCode}-${autoDamage.damageCode}`;
            payload.condition.damages.forEach(conditionDamage => {
                if (conditionDamage.damageKey.startsWith(autoDamageKey)) {
                    completeAutoDamageList.push(conditionDamage)
                }
            })
        })
    }
    return completeAutoDamageList;
}

const getNonKeyAutoDamages = (payload) => {
    const nonKeyDamages = []
    const keyRuleTypes = KEY_TYPES.map(keyType => keyType.keyRuleType)
    payload.damageRules.damages.forEach(damage => {
        if (!keyRuleTypes.includes(damage.item)) {
            nonKeyDamages.push(damage)
        }
    })
    return nonKeyDamages;
}

const getRequiredKeyAutoDamages = (payload) => {
    const requiredKeyDamages = []
    payload.damageRules.damages.forEach(damage => {
        let rule = payload.damageRules.rules?.keys?.find(r => r.type === damage.item)
        if (!!rule && !rule.optional) {
            requiredKeyDamages.push(damage)
        }
    })
    return requiredKeyDamages;
}

const getZeroKeysAutoDamages = (payload, requiredKeyDamages) => {
    const keysWithZeroQuantity = payload.condition.keys?.filter(key => key.quantity === 0)
    const zeroKeyDamages = [];
    const zeroKeysItemSubItems = keysWithZeroQuantity.map(key => {
        let k = KEY_TYPES.find(keyType => keyType.type === key.type)
        return k?.itemSubItem;
    }).filter(itemSubItem => !!itemSubItem)
    let requiredItemSubItems = requiredKeyDamages.map(damage => `${damage.itemCode}${damage.subItemCode}`)
    payload.condition.damages.forEach(damage => {
        let itemSubItem = `${damage.itemCode}${damage.subItemCode}`;
        if (zeroKeysItemSubItems.includes(itemSubItem) && !requiredItemSubItems.includes(itemSubItem)) {
            zeroKeyDamages.push(damage);
        }
    })
    return zeroKeyDamages;
}