import React, {useEffect} from 'react';
import Damages from "./Damages";
import Row from "@prism/row";
import Col from "@prism/col";
import ButtonGroup from "@prism/buttongroup";
import Button from "@prism/button";
import {showAddDamageView, showDamageSummaryView} from "../../../actions/globalDisplayActions";
import {connect} from "react-redux";
import Message from "@prism/message";
import {DAMAGES_MISSING_PHOTOS_MESSAGE, DAMAGES_MISSING_PRICING_MESSAGE} from "../../../utils/constants";
import {ENABLE_ADD_DAMAGES} from "../../../config/env";
import DamageEditWrapper from "./DamageEditWrapper";
import {clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";

const DamageListView = (props) => {

    useEffect(() => {
        if (globalDisplay.damageListView !== 'addDamage') {
            window.scrollTo(0, 0);
        }
    })

    const {globalDisplay, condition} = props;

    const handleViewButtonClick = (e) => {
        if (e.currentTarget.name === 'addDamage') {
            props.showAddDamageView();
        } else {
            props.showDamageSummaryView();
        }
    }

    return (
        <>
            {condition.missingPricing && <Message id="missing-prices-message" color="danger"><i
                className="icon prism-icon-notification-circle" color="danger"/>
                {DAMAGES_MISSING_PRICING_MESSAGE}
            </Message>}
            {condition.missingPhotos && <Message id="missing-photos-message" color="danger"><i
                className="icon prism-icon-camera" color="danger"/>
                {DAMAGES_MISSING_PHOTOS_MESSAGE}
            </Message>}
            {ENABLE_ADD_DAMAGES === 'true' ?
                <div id="damage-list">
                    <Row>
                        <Col xs={12}>
                            <ButtonGroup className='mx-0 px-0' style={{width: '100%'}}>
                                <Button outline active={globalDisplay.damageListView === 'addDamage'}
                                        className="button-white"
                                        onClick={handleViewButtonClick} name="addDamage"
                                        style={{width: '22%', fontSize: '1.4rem'}}>ADD DAMAGES</Button>
                                <Button outline active={globalDisplay.damageListView === 'summary'}
                                        className="button-white"
                                        onClick={handleViewButtonClick} name="summary"
                                        style={{width: '22%', fontSize: '1.4rem'}}>DAMAGE SUMMARY</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {
                        {
                            addDamage: <DamageEditWrapper/>,
                            summary: <Damages/>
                        }[globalDisplay.damageListView]
                    }
                </div>
                : <Damages/>
            }
        </>
    )
};

const mapDispatchToProps = {
    showDamageSummaryView,
    showAddDamageView,
    clearSelectedFlatCarPanel
};

function mapStateToProps({globalDisplay, suggestedDamages, condition}) {
    return {globalDisplay, suggestedDamages, condition};
}

export default connect(mapStateToProps, mapDispatchToProps)(DamageListView);