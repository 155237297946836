import React, {useState} from "react";
import {connect} from "react-redux";
import Col from "@prism/col";
import Row from "@prism/row";
import Section from "../../common/Section";
import {ViewPhotoButton} from "./ViewPhotoButton";
import DeleteDamageButton from "./DeleteDamageButton";
import EditDamageButton from "./EditDamageButton";
import {deleteDamage} from "../../../actions/conditionActions";
import {showAddEditDamage} from "../../../actions/globalDisplayActions";

import CapturePhotoButton from "./CapturePhotoButton";
import DamageImageModal from "./DamageImageModal";
import {getActiveImage} from "../../../actions/damageImageActions";
import {
  isAutomatedDamage,
  damageRequiresPhoto,
  isNativeApp,
  isPricingEmptyWithValidAction
} from "../../../utils/utils";
import * as pricingUtils from "./PricingUtils"
import {FYU_DAMAGE_ANALYSIS} from "../../../utils/constants";
import "../../../styles/damage-panel.css"

const DamageLineItem = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleDeleteDamage = () => {
    props.deleteDamage(props.damage, props.condition);
  };

  const showImage = () => {
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  const capturePhoto = (props) => {
    const { damageKey, auctionCode, workOrderNumber, vin, category, title } = props;

    if (isNativeApp()) {
      console.log(
        "capture photo with",
        auctionCode,
        workOrderNumber,
        damageKey,
        category
      );
      window.NativeApp.capturePhoto(
        workOrderNumber,
        vin,
        damageKey,
        category,
        title
      );
    } else {
      console.log(
        "camera not available in web view.",
        auctionCode,
        workOrderNumber,
        damageKey
      );
    }
  };

  const { damage, condition } = props;
  const { workOrderNumber } = props.workOrderInfo;
  const {vin} = props.designatedDescription
  const isCRSO = pricingUtils.isCRSO(condition)

  const isAutoDamage = isAutomatedDamage(props.automatedDamages, damage.damageKey)

  const editingDisabled = !isCRSO && isAutoDamage;
  const invalidRow = ((isCRSO && isPricingEmptyWithValidAction(damage)) || (damageRequiresPhoto(damage, condition.conditionType) && !damage.hasImage)) ? 'invalid-row' : '';

  return (
    <Section heading={damage.item}
             containerBodyClass="pt-0"
             id={"item-" + damage.damageKey}
             suggestedDamage={damage.sourcePlatform === FYU_DAMAGE_ANALYSIS}
    >
      <DamageImageModal
        showModal={modalOpen}
        toggle={toggleModal}
        imageTitle={damage.item}
        damageKey={damage.damageKey}
        damage={damage}
        getActiveImage={props.getActiveImage}
        conditionType={condition.conditionType}
      />

      <Col className={`damage-summary-row ${invalidRow} ${editingDisabled ? " disabled" : ""}`}>

        <Row>
          <Col className="damage-summary-col">
            <label id={"damage-" + damage.damageKey}>{damage.damage}</label>
          </Col>
          <Col className="damage-summary-col">
            <label id={"severity-" + damage.damageKey}>{damage.severity}</label>
          </Col>
        </Row>

        <Row className="damage-summary-buttons py-2">
          <Col className="left">
            <DeleteDamageButton
                disabled={isAutoDamage}
                damage={damage}
                onClick={handleDeleteDamage}
            />
          </Col>
          <Col className="central">
            {damage.hasImage ? (
                <ViewPhotoButton
                    onClick={showImage}
                    disabled={false}
                    id={"view-image-" + damage.damageKey}
                    className="my-0"
                />
            ) : (
                <CapturePhotoButton
                    id={"capture-image-" + damage.damageKey}
                    className="my-0"
                    onClick={() =>
                        capturePhoto({
                          workOrderNumber: workOrderNumber,
                          vin: vin,
                          damageKey: damage.damageKey,
                          category: "DAMAGE",
                          title: damage.item
                        })
                    }
                />
            )}
          </Col>
          <Col className="right">
            <EditDamageButton
                id={"edit-" + damage.damageKey}
                onClick={() => props.showAddEditDamage(damage)}
                disabled={editingDisabled}
            />
          </Col>
        </Row>
      </Col>
    </Section>
  );
};

const matchDispatchToProps = {
  deleteDamage,
  showAddEditDamage,
  getActiveImage,
};

function mapStateToProps({ automatedDamages, workOrderInfo, condition, designatedDescription }) {
  return { automatedDamages, workOrderInfo, condition, designatedDescription };
}

export default connect(mapStateToProps, matchDispatchToProps)(DamageLineItem);
