import React, {useState} from "react";
import Row from "@prism/row";
import Col from "@prism/col";
import {
    redecodeVin,
    setDesignatedDescription,
    updateBlockType,
    updateBodyStyle,
    updateCountry,
    updateCylinderCount,
    updateDisplacement,
    updateDrivetrain,
    updateFuelType,
    updateInduction,
    updateInstalledColor,
    updateMake,
    updateModel,
    updateRoofType,
    updateSeatTrim,
    updateSubSeries,
    updateTransmission,
    updateTrim,
    updateWheels,
    updateYear
} from "../../../actions/designatedDescriptionActions";
import {
    hideRedecodeModal,
    hideTrimFinder,
    showRedecodeModal,
    showTrimFinder
} from "../../../actions/globalDisplayActions";
import {connect} from "react-redux";
import Api from "../../Api";
import Vin from "./Vin";
import Year from "./Year";
import BodyStyle from "./BodyStyle";
import Make from "./Make";
import Model from "./Model";
import Trim from "./Trim";
import Country from "./Country";
import Drivetrain from "./Drivetrain";
import Transmission from "./Transmission";
import Wheels from "./Wheels";
import InteriorColor from "./InteriorColor";
import {CANCEL, DECODE, EXTERIOR, INTERIOR, REDECODE_BODY, REDECODE_HEADER} from "../../../utils/constants";
import ExteriorColor from "./ExteriorColor";
import SeatTrim from "./SeatTrim";
import FuelType from "./FuelType";
import CylinderCount from "./CylinderCount";
import Displacement from "./Displacement";
import Induction from "./Induction";
import Odometer from "../condition/Odometer";
import FuelLevel from "../condition/FuelLevel";
import Battery from "../condition/Battery";
import SubSeries from "./SubSeries";
import BlockType from "./BlockType";
import SpinnerOverlay from "../../Spinner/SpinnerOverlay";
import TrimFinder from "./TrimFinder";
import TrimSelector from "./trimFinder/TrimSelector";
import RoofType from "./RoofType";
import ConfirmationOrDecisionModal from "../../ConfirmationOrDecisionModal";

const Configure = (props) => {
    const {
        designatedDescriptionResponse,
        designatedDescription,
        unit,
        updateYear,
        updateCountry,
        updateBodyStyle,
        globalDisplay,
        updateMake,
        updateModel,
        updateTrim,
        updateDrivetrain,
        updateFuelType,
        updateBlockType,
        updateCylinderCount,
        updateDisplacement,
        updateInduction,
        updateRoofType,
        updateTransmission,
        updateWheels,
        taxonomyData,
        updateSubSeries,
        showTrimFinder,
        hideTrimFinder
    } = props;

    const [isRedecodeLoading, setIsRedecodeLoading] = useState(false);

    const redecodeVehicle = async () => {
        props.hideRedecodeModal();
        setIsRedecodeLoading(true);
        let vcrResponse = await Api.getCatalogVehicleForSelectedTrim({
            vin: unit.vin,
            reDecodeVin: true
        });
        setIsRedecodeLoading(false);
        props.redecodeVin(vcrResponse);
    }

    const emptyEvent = {currentTarget: {value: ''}};
    const emptyOption = {name: '', id: undefined}

    const onYearChange = (event) => {
        updateYear(event.currentTarget?.value)
    }

    const onMakeChange = (option) => {
        updateMake(option ? option : emptyOption)
        onModelChange(emptyOption)
    }

    const onModelChange = (option) => {
        updateModel(option ? option : emptyOption)
        onTrimChange(emptyEvent);
    }

    const onTrimChange = (event) => {
        if (!designatedDescriptionResponse) {
            updateTrim(event.currentTarget?.value)
        } else {
            props.setDesignatedDescription(event.target.value)
        }

    }

    const onSubSeriesChange = (event) => {
        updateSubSeries(event.currentTarget?.value, designatedDescription.catalogId);
    }

    const onFuelTypeChange = (event) => {
        updateFuelType(event.currentTarget?.value)
    }

    const onBlockTypeChange = (event) => {
        updateBlockType(event.currentTarget?.value)
    }

    const onCylinderCountChange = (options, event) => {
        const cylinderCount = event.currentTarget?.value;
        const option = options.find(option => option.name === cylinderCount);
        updateCylinderCount(option);
        onDisplacementChange([emptyOption], emptyEvent)
    }

    const onDisplacementChange = (options, event) => {
        const displacement = event.currentTarget?.value;
        const option = options.find(option => option.name === displacement);
        updateDisplacement(option);
    }

    const onInductionChange = (event) => {
        updateInduction(event.currentTarget?.value)
    }

    const onCountryChange = (options, event) => {
        const code = event.currentTarget?.value
        const option = options.find(option => option.code === code)
        updateCountry(option)
    }

    const onBodyStyleChange = (options, event) => {
        const name = event.currentTarget?.value
        const option = options.find(option => option.name === name)
        updateBodyStyle(option)
        onYearChange(emptyEvent)
        onMakeChange(emptyOption)
    }

    let hasMultipleTrims = () => {
        return designatedDescriptionResponse !== null
            && designatedDescriptionResponse !== undefined
            && designatedDescriptionResponse.vcrResponse?.vehicleTrims?.length > 1;
    }
    const onTransmissionChange = (event) => {
        updateTransmission(event.currentTarget?.value)
    }
    const onDrivetrainChange = (event) => {
        updateDrivetrain(event.currentTarget?.value)
    }

    const onWheelsChange = (event) => {
        updateWheels(event.currentTarget?.value)
    }

    const onRoofTypeChange = (event) => {
        updateRoofType(event.currentTarget?.value)
    }

    const onExteriorColorChange = (e) => {
        props.updateInstalledColor(e.target.value, EXTERIOR, designatedDescription.catalogId)
    }

    const onInteriorColorChange = async (e) => {
        await props.updateInstalledColor(e.target.value, INTERIOR, designatedDescription.catalogId)
    }

    const onSeatTrimChange = (e) => {
        props.updateSeatTrim(designatedDescription.colors.interior[0], e.target.value);
    }

    const hasTrimFinderQuestions = () => {
        return designatedDescriptionResponse?.vcrResponse?.questions?.length > 0;
    }


    return (
        <>
            <Col xs={12}>
                <Row xs={12}>
                    <Col xs={12}>
                        {isRedecodeLoading && (
                            <SpinnerOverlay id='loading-spinner' showCancelButton={false} size={100}/>
                        )}
                        {/* RE-DECODE CONFIRMATION MODAL */}
                        <ConfirmationOrDecisionModal
                            shouldShowModal={props.globalDisplay.shouldShowRedecodeModal}
                            modalHeader={REDECODE_HEADER}
                            modalBody={REDECODE_BODY}
                            modalType="redecode"
                            confirmationText={DECODE}
                            cancelText={CANCEL}
                            handleClose={props.hideRedecodeModal}
                            handleContinue={redecodeVehicle}
                        />
                        {hasTrimFinderQuestions() &&
                            <TrimSelector designatedDescriptionResponse={designatedDescriptionResponse}
                                          globalDisplay={globalDisplay}
                                          hideTrimFinder={hideTrimFinder}
                                          onTrimChange={onTrimChange}/>}
                        <Vin unit={unit}
                             designatedDescriptionResponse={designatedDescriptionResponse}
                             showRedecodeModal={props.showRedecodeModal}/>
                        <Country designatedDescription={designatedDescription}
                                 hasDDSResponse={!!designatedDescriptionResponse}
                                 onCountryChange={onCountryChange}/>
                        <BodyStyle designatedDescription={designatedDescription}
                                   hasDDSResponse={!!designatedDescriptionResponse}
                                   onBodyStyleChange={onBodyStyleChange}/>
                        <Year designatedDescription={designatedDescription}
                              hasDDSResponse={!!designatedDescriptionResponse}
                              onYearChange={onYearChange}/>
                        <Make designatedDescription={designatedDescription}
                              hasDDSResponse={!!designatedDescriptionResponse}
                              onMakeChange={onMakeChange}
                              globalDisplay={globalDisplay}/>
                        <Model designatedDescription={designatedDescription}
                               hasDDSResponse={!!designatedDescriptionResponse}
                               onModelChange={onModelChange}
                               globalDisplay={globalDisplay}/>
                        <Trim designatedDescription={designatedDescription}
                              designatedDescriptionResponse={designatedDescriptionResponse}
                              onTrimChange={onTrimChange}
                              globalDisplay={globalDisplay}
                              hasMultipleTrims={hasMultipleTrims()}/>
                        <TrimFinder designatedDescriptionResponse={designatedDescriptionResponse}
                                        onClick={showTrimFinder}/>
                        <SubSeries designatedDescription={designatedDescription}
                                   DDSResponse={designatedDescriptionResponse}
                                   onSubSeriesChange={onSubSeriesChange}/>
                        <FuelType designatedDescription={designatedDescription}
                                  hasDDSResponse={!!designatedDescriptionResponse}
                                  onFuelTypeChange={onFuelTypeChange}/>
                        <BlockType designatedDescription={designatedDescription}
                                   hasDDSResponse={!!designatedDescriptionResponse}
                                   onBlockTypeChange={onBlockTypeChange}/>
                        <CylinderCount designatedDescription={designatedDescription}
                                       hasDDSResponse={!!designatedDescriptionResponse}
                                       onCylinderCountChange={onCylinderCountChange}/>
                        <Displacement designatedDescription={designatedDescription}
                                      hasDDSResponse={!!designatedDescriptionResponse}
                                      DDSResponse={designatedDescriptionResponse}
                                      globalDisplay={globalDisplay}
                                      onDisplacementChange={onDisplacementChange}
                                      updateCylinderCount={updateCylinderCount}/>
                        <Induction designatedDescription={designatedDescription}
                                   DDSResponse={designatedDescriptionResponse}
                                   onInductionChange={onInductionChange}/>
                        <Drivetrain designatedDescription={designatedDescription}
                                    DDSResponse={designatedDescriptionResponse}
                                    onDrivetrainChange={onDrivetrainChange}/>
                        <Transmission designatedDescription={designatedDescription}
                                      designatedDescriptionResponse={designatedDescriptionResponse}
                                      hasDDSResponse={!!designatedDescriptionResponse}
                                      onTransmissionChange={onTransmissionChange}/>
                        <ExteriorColor designatedDescription={designatedDescription}
                                       designatedDescriptionResponse={designatedDescriptionResponse}
                                       onExteriorColorChange={onExteriorColorChange}
                                       taxonomyData={taxonomyData}/>
                        <InteriorColor designatedDescription={designatedDescription}
                                       designatedDescriptionResponse={designatedDescriptionResponse}
                                       onInteriorColorChange={onInteriorColorChange}
                                       taxonomyData={taxonomyData}/>
                        <SeatTrim designatedDescription={designatedDescription}
                                  designatedDescriptionResponse={designatedDescriptionResponse}
                                  onSeatTrimChange={onSeatTrimChange}
                                  taxonomyData={taxonomyData}/>
                        <Wheels designatedDescription={designatedDescription}
                                hasDDSResponse={!!designatedDescriptionResponse}
                                onWheelsChange={onWheelsChange}/>
                        {!!designatedDescriptionResponse &&
                            <RoofType designatedDescription={designatedDescription}
                                      designatedDescriptionResponse={designatedDescriptionResponse}
                                      onRoofTypeChange={onRoofTypeChange}/>}
                        <Odometer/>
                        <FuelLevel/>
                        <Battery/>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

const mapDispatchToProps = {
    updateInstalledColor,
    updateSeatTrim,
    setDesignatedDescription,
    updateWheels,
    showRedecodeModal,
    hideRedecodeModal,
    redecodeVin,
    updateYear,
    updateCountry,
    updateBodyStyle,
    updateMake,
    updateModel,
    updateTrim,
    updateFuelType,
    updateBlockType,
    updateCylinderCount,
    updateDisplacement,
    updateInduction,
    updateTransmission,
    updateDrivetrain,
    updateSubSeries,
    showTrimFinder,
    hideTrimFinder,
    updateRoofType
};

function mapStateToProps(
    {
        designatedDescriptionResponse,
        designatedDescription,
        unit,
        globalDisplay,
        taxonomyData
    }
) {
    return {
        designatedDescriptionResponse,
        designatedDescription,
        unit,
        globalDisplay,
        taxonomyData
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Configure);