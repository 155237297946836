import React from "react";
import OutlinePanel from "../../common/OutlinePanel";
import Button from "@prism/button";
import Row from "@prism/row";
import Col from "@prism/col";
import Form from "@prism/form";
import Input from "@prism/input";
import Message from "@prism/message";
import {ActionDropdown, ChargeableDropdown, DamageDropdown, SeverityDropdown} from "../damage/artComponents";
import TextCounter from "../../common/TextCounter";
import {addAutoDamage, addDamage} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {hasActiveImage} from "../../../actions/damageImageActions";
import TakePhoto from "../damage/capturePhoto/TakePhoto";
import {showExteriorFlatCarView} from "../../../actions/globalDisplayActions";
import {DamagePricingInputs} from "../../../utils/DamagePricingInputs";
import SlidePanel from "../../SlidePanel";
import Pricing from "../Pricing";
import SpinnerOverlay from "../../Spinner/SpinnerOverlay";
import {MISSING_ESTIMATE_MESSAGE, MISSING_PRICING_MESSAGE} from "../../../utils/constants";
import ItemDropdown from "../damage/artComponents/ItemDropdown";
import * as pricingUtils from "../damage/PricingUtils"
import {damageRequiresPhoto} from "../../../utils/utils";
import Api from "../../Api";

class DamageModalDetails extends React.Component {
    state = {
        validation: {
            damageIsValid: true,
            severityIsValid: true,
            chargeableIsValid: true
        },
        chargeable: null,
        chargeableDescription: null,
        item: this.props.damage.item,
        itemCode: this.props.damage.itemCode,
        subItemCode: this.props.damage.subItemCode,
        damage: this.props.damage.damage,
        showPricing: false,
        pricingTypes: [],
        damageCode: this.props.damage.damageCode,
        locationCode: this.props.damage.locationCode,
        severity: this.props.damage.severity,
        severityCode: this.props.damage.severityCode,
        action: this.props.damage.action ? this.props.damage.action : null,
        actionCode: this.props.damage.actionCode ? this.props.damage.actionCode : null,
        damageKey: this.props.damage.damageKey ? this.props.damage.damageKey : '',
        damageImageRequired: 'N',
        showPricingInputError: false,
        isApiLoading: false,
        notes: this.props.damage.notes
    };

    componentDidMount = async () => {
        if (pricingUtils.isCRSO(this.props.condition)) {
            await this.handlePricing();
            await this.validatePricing();
        }
    }

    photoIsRequired = () => {
        return damageRequiresPhoto(this.compiledDamageObject(), this.props.condition.conditionType);
    };

    setStatusMessage = (message) => {
        console.log("message", message)
    };

    handleItemChange = (item) => {
        this.setState({
            ...item,
            damageCode: "",
            severityCode: "",
            actionCode: "",
            chargeable: ""
        })
    };

    handleDamageChange = ({target: {options, selectedIndex, value}}) => {
        if (this.state.damageCode !== value) {
            this.setState({
                damageCode: value,
                damage: options[selectedIndex].text,
                statusMessage: {text: '', color: ''}
            }, () => this.handleSeverityChange({target: {value: '', options: [{text: ''}], selectedIndex: 0}}));
        }
    };

    handleSeverityChange = ({target: {options, selectedIndex, value}}) => {
        if (this.state.severityCode !== value) {
            this.setState({
                severityCode: value,
                severity: options[selectedIndex].text,
                statusMessage: {text: '', color: ''},
            }, () => this.handleActionChange({target: {value: '', options: [{text: ''}], selectedIndex: 0}}))
        }
    };

    handleActionChange = async ({target: {options, selectedIndex, value}}) => {
        if (this.state.actionCode !== value) {
            await this.setState({
                actionCode: value,
                action: options[selectedIndex].text,
                statusMessage: {text: '', color: ''}
            }, () => {
                this.setState({damageKey: pricingUtils.getDamageKey(this.state)}, async () => {
                    if (!pricingUtils.isCRSO(this.props.condition) || await pricingUtils.damageDoesNotNeedPricing(value)) {
                        await this.resetPricingAndErrorFlag()
                    } else {
                        await this.handlePricing();
                    }
                })
            });
        }
    };

    handleChargeableChange = ({target: {options, selectedIndex, value}}) => {
        if (this.state.chargeable !== value) {
            this.setState({
                chargeable: value,
                chargeableDescription: options[selectedIndex].text
            })
        }
    };

    handleSaveDamage = async () => {
        const damage = this.compiledDamageObject()
        const artItem = await Api.getArtItem(damage.itemCode, damage.subItemCode);

        if (!!artItem.panelId) {
            damage.panelId = artItem.panelId;
        }

        if (this.props.onSaveClick) {
            this.props.onSaveClick();
        }

        if (this.props.isStructural) {
            this.props.addDamage(damage);
        } else {
            this.props.addAutoDamage(damage);
        }
        this.props.toggle();
    };

    handleNotesChange = (event) => {
        this.setState({
            notes: event.value
        })
    };

    handleEditPartDescriptionChange = (event) => {
        this.setState({
            partDescription: event.value,
        });
    };

    compiledDamageObject = () => {
        return {
            item: this.state.item,
            itemCode: this.state.itemCode,
            subItemCode: this.state.subItemCode,
            damage: this.state.damage,
            damageCode: this.state.damageCode,
            severity: this.state.severity,
            severityCode: this.state.severityCode,
            action: this.state.action,
            actionCode: this.state.actionCode,
            locationCode: this.state.locationCode,
            chargeable: this.state.chargeable,
            chargeableDescription: this.state.chargeableDescription,
            notes: this.state.notes,
            damageKey: this.state.damageKey,
            repairLaborHours: parseFloat(this.state.repairLaborHours),
            repairLaborCost: parseFloat(this.state.repairLaborCost),
            paintLaborHours: parseFloat(this.state.paintLaborHours),
            paintLaborCost: parseFloat(this.state.paintLaborCost),
            partLaborHours: parseFloat(this.state.partLaborHours),
            partLaborCost: parseFloat(this.state.partLaborCost),
            partCost: parseFloat(this.state.partCost),
            finalPartCost: parseFloat(this.state.finalPartCost),
            pricePlanIds: this.state.pricePlanIds,
            partDescription: this.state.partDescription,
            hasImage: this.props.hasActiveImage(this.state.damageKey),
            damageImageRequired: this.state.damageImageRequired
        }
    };

    handlePriceChange = async (event) => {
        await this.updateInputState(event)
        await this.validatePricing()
    };

    updateInputState = (event) => {
        let value = parseFloat(event?.target.value);
        if (isNaN(value)) {
            value = '';
        } else {
            value = Math.round(value * 100) / 100;
        }
        return new Promise((resolve) => {
            this.setState({[event.currentTarget.name]: value}, resolve)

        })
    }

    handleInputValidation = (dataFromChild) => {
        this.setState(dataFromChild);
    }

    formatCurrency = async (event) => {
        this.setState({
            [event.target.name]: pricingUtils.formatCurrency(event)
        });
    };

    resetPricingFields() {
        this.setState({
            repairLaborHours: null,
            repairLaborCost: null,
            paintLaborHours: null,
            paintLaborCost: null,
            partLaborHours: null,
            partLaborCost: null,
            partCost: null,
            finalPartCost: null,
            pricePlanIds: null
        });
    }

    resetPricingAndErrorFlag = async () => {
        this.resetDamagePricing();
        this.setState({showPricingInputError: false, showEstimateError: false})
    }

    getEstimate = async () => {
        let estimateResponse;
        try {
            this.setState({isApiLoading: true});
            estimateResponse = await pricingUtils.fetchEstimate(this.props, this.state);
            this.setState({isApiLoading: false});
        } catch (e) {
            this.setState({isApiLoading: false});
            await this.handleShowEstimateError();
            throw new Error("Error fetching estimate");
        }
        return estimateResponse;
    }

    async handlePricing() {
        this.resetPricingFields();
        let estimateResponse;
        try {
            estimateResponse = await this.getEstimate();
        } catch (e) {
            return;
        }
        const {
            overlays,
            pricingTypes
        } = await pricingUtils.getPricingTypesAndOverlays(estimateResponse)
        if (!!estimateResponse && Object.keys(estimateResponse).length !== 0) {
            await this.setDamagePricePlans(estimateResponse)
            await this.setPricingProperties(pricingTypes, overlays);
            const callPricing = overlays.length !== 0 && pricingTypes.length === 0;
            if (callPricing) {
                await this.getAndValidatePricing();
            }
        } else {
            this.resetDamagePricing();
            const pricingErrors = pricingUtils.getPricingErrors(pricingUtils.isCRSO(this.props.condition), this.state);
            this.setState({
                showPricingInputError: pricingErrors.showPricingInputError,
                showEstimateError: pricingErrors.showEstimateError
            })
        }
    }

    async setPricingProperties(pricingTypes, overlays) {
        if (pricingTypes.length === 0 && overlays.length === 0) {
            this.resetDamagePricing();
            await this.validatePricing();
        }
        this.setState({
            pricingTypes,
            showPricing: pricingTypes.length !== 0
        });
    }

    async getAndValidatePricing() {
        const damageKey = pricingUtils.getDamageKey(this.state, false)
        const response = await this.getPricing(pricingUtils.buildDamage(this.state, damageKey));
        if (Array.isArray(response) && !!response.length) {
            await this.updateDamagePricing(response, false)
        }
        await this.validatePricing();
    }

    handleShowEstimateError = async () => {
        this.resetDamagePricing();
        this.setState({
            disabled: false,
            showPricingInputError: true,
            showEstimateError: true
        })
    }

    setDamagePricePlans = async (estimateResponse) => {
        const pricePlans = await pricingUtils.getPricePlans(estimateResponse);
        let pricePlanIds = this.state.pricePlanIds || [];
        pricePlanIds = pricePlanIds.concat(pricePlans);
        this.setState({pricePlanIds})
    };

    getPricing = async (damage) => {
        let response;
        try {
            this.setState({isApiLoading: true});
            response = await pricingUtils.fetchPricing(this.props, damage);
            this.setState({isApiLoading: false});
        } catch (e) {
            this.setState({isApiLoading: false});
            this.setStatusMessage("Error fetching pricing");
            return e;
        }
        return response;
    }

    validatePricing = async () => {
        if (pricingUtils.isCRSO(this.props.condition)) {
            const pricingErrors = pricingUtils.getPricingErrors(pricingUtils.isCRSO(this.props.condition), this.state);
            this.setState({
                showPricingInputError: pricingErrors.showPricingInputError,
                showEstimateError: pricingErrors.showEstimateError
            })
        }
    }

    updateDamagePricing = async (pricingResponse, isHours) => {
        const finalPricing = await pricingUtils.getFinalPricing(pricingResponse);
        if (isHours) {
            await this.setState({
                partLaborHours: finalPricing.partLaborHours,
                partLaborCost: finalPricing.partLaborCost,
                paintLaborHours: finalPricing.paintLaborHours,
                paintLaborCost: finalPricing.paintLaborCost,
                repairLaborHours: finalPricing.repairLaborHours,
                repairLaborCost: finalPricing.repairLaborCost,
                partDescription: finalPricing.partDescription,
                pricingDamageImageRequired: finalPricing.pricingDamageImageRequired,
                damageImageRequired: finalPricing.damageImageRequired
            })
        } else {
            await this.setState({
                partLaborHours: finalPricing.partLaborHours,
                partLaborCost: finalPricing.partLaborCost,
                paintLaborHours: finalPricing.paintLaborHours,
                paintLaborCost: finalPricing.paintLaborCost,
                repairLaborHours: finalPricing.repairLaborHours,
                repairLaborCost: finalPricing.repairLaborCost,
                partCost: finalPricing.partCost,
                finalPartCost: finalPricing.finalPartCost,
                partDescription: finalPricing.partDescription,
                pricingDamageImageRequired: finalPricing.pricingDamageImageRequired,
                damageImageRequired: finalPricing.damageImageRequired
            });
        }
        this.setState({isApiLoading: false});
    };

    resetDamagePricing = () => {
        this.setState({
            repairLaborCost: '0.00',
            repairLaborHours: '0',
            paintLaborCost: '0.00',
            paintLaborHours: '0',
            partLaborCost: '0.00',
            partLaborHours: '0',
            partCost: '0.00',
            finalPartCost: '0.00'
        });
    }

    handlePricingUpdate = (damage) => {
        this.setState({
            pricePlanIds: damage.pricePlanIds,
            paintLaborHours: null,
            paintLaborCost: null,
            partLaborHours: null,
            partLaborCost: null,
            partCost: null,
            finalPartCost: null
        }, async () => {
            await this.getAndValidatePricing();
        });
    };

    hidePricingOverlay = async () => {
        await this.setState({
            showPricing: false
        }, () => {
        })
    };

    cancelPricingOverlay = async () => {
        this.setState({
            repairLaborCost: '0.00',
            repairLaborHours: 0.0,
            paintLaborCost: '0.00',
            paintLaborHours: 0.0,
            partLaborCost: '0.00',
            partLaborHours: 0.0,
            partCost: '0.00',
            finalPartCost: '0.00',
            showPricing: false,
            pricePlanIds: []
        })
    };

    requiredFieldsCompleted = () => {
        return (
            this.hasValidDamage() && (this.photoIsRequired()
                ? this.props.hasActiveImage(this.state.damageKey)
                : true)
        );
    };

    hasValidDamage = () => {
        return this.state.itemCode &&
            this.state.subItemCode &&
            this.state.damageCode &&
            this.state.severityCode &&
            this.state.chargeable
    }

    handleManualUpdatePricing = async (event) => {
        if (pricingUtils.costFieldWasUpdated(event.target.name)) {
            await this.formatCurrency(event);
            await this.updateManualDamagePricing();
            await this.validatePricing();
        }
    }

    updateManualDamagePricing = async () => {
        const partLaborCost = pricingUtils.getFinalCost(this.state.partLaborCost);
        const paintLaborCost = pricingUtils.getFinalCost(this.state.paintLaborCost);
        const repairLaborCost = pricingUtils.getFinalCost(this.state.repairLaborCost);
        const finalPartCost = pricingUtils.getFinalCost(this.state.finalPartCost);
        const partCost = pricingUtils.getFinalCost(this.state.partCost);
        const damageImageRequired = pricingUtils.getDamageImageRequired(repairLaborCost, paintLaborCost, partLaborCost, finalPartCost, partCost, this.state.pricingDamageImageRequired)
        this.setState({
            partLaborCost,
            paintLaborCost,
            repairLaborCost,
            finalPartCost,
            partCost,
            damageImageRequired
        });
    }

    handleUpdatePricing = async (event) => {
        const pricingField = event.target.name;
        const damageKey = pricingUtils.getDamageKey(this.state, false)
        const damage = pricingUtils.getDamageForManualPricingInput(pricingField, this.state, damageKey);
        const isHours = pricingField !== 'partCost';
        const response = await this.getPricing(damage);
        if (Array.isArray(response) && !!response.length) {
            await this.updateDamagePricing(response, isHours)
        }
        await this.validatePricing();
    };

    saveButtonDisabled = () => {
        return !this.requiredFieldsCompleted()
            || (pricingUtils.isCRSO(this.props.condition) && this.state.showPricingInputError)
            || this.state.isApiLoading
    };

    render() {
        const isCRSO = pricingUtils.isCRSO(this.props.condition);

        const isStructural = this.props.isStructural;

        // account object will be null after clearing vehicle work order data from state, by clicking m logo from nativeapp.
        if (!this.props.account) {
            return null;
        }
        return (
            <div id="add-damage-content" className="bg-white">
                {this.state.isApiLoading && (
                    <SpinnerOverlay id='loading-spinner' showCancelButton={false} size={100}/>
                )}
                <Row className="mx-0">
                    <Col>
                        <Row>
                            <Col xs={12}>
                                <Row className="mx-0">
                                    {!isStructural && <Form.Group className="flex-grow-1">
                                        <Input.Label className="font-weight-bold">Item</Input.Label>
                                        <Input type="text" id="item-input" value={this.state.item} disabled/>
                                    </Form.Group>}
                                    {isStructural && <ItemDropdown onChange={this.handleItemChange}/>}
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Input.Label className="font-weight-bold">Damage</Input.Label>
                                    <DamageDropdown onChange={this.handleDamageChange}
                                                    value={this.state.damageCode}
                                                    item={{
                                                        itemCode: this.state.itemCode,
                                                        subItemCode: this.state.subItemCode
                                                    }}
                                                    description={this.state.damage}
                                                    groupCode={this.props.account.groupCode}
                                                    categoryCode={this.props.consignment.categoryCode}
                                                    setStatusMessage={this.setStatusMessage}
                                                    disabled={!isStructural}
                                    />
                                    {!this.state.validation.damageIsValid &&
                                        <div className="mt-8 invalid-feedback" style={{display: 'block'}}><span><i
                                            className="icon prism-icon-notification-circle text-danger"/> Required</span>
                                        </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Input.Label className="font-weight-bold">Severity</Input.Label>
                                    <SeverityDropdown onChange={this.handleSeverityChange}
                                                      value={this.state.severityCode}
                                                      item={{
                                                          itemCode: this.state.itemCode,
                                                          subItemCode: this.state.subItemCode
                                                      }}
                                                      description={this.state.severity}
                                                      damageCode={this.state.damageCode}
                                                      groupCode={this.props.account.groupCode}
                                                      categoryCode={this.props.consignment.categoryCode}
                                                      setStatusMessage={this.setStatusMessage}
                                                      disabled={!isStructural}
                                    />
                                    {!this.state.validation.severityIsValid &&
                                        <div className="mt-8 invalid-feedback" style={{display: 'block'}}><span><i
                                            className="icon prism-icon-notification-circle text-danger"/> Required</span>
                                        </div>}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Input.Label className="font-weight-bold">Chargeable</Input.Label>
                                    <ChargeableDropdown onChange={this.handleChargeableChange}
                                                        value={this.state.chargeable}
                                                        description={this.state.chargeableDescription}
                                                        item={{
                                                            itemCode: this.state.itemCode,
                                                            subItemCode: this.state.subItemCode
                                                        }}
                                                        damageCode={this.state.damageCode}
                                                        severityCode={this.state.severityCode}
                                                        groupCode={this.props.account.groupCode}
                                                        categoryCode={this.props.consignment.categoryCode}
                                                        setStatusMessage={this.setStatusMessage}
                                    />
                                    <div style={{display: "none"}}>
                                        <ActionDropdown onChange={this.handleActionChange}
                                                        value={this.state.actionCode}
                                                        item={{
                                                            itemCode: this.state.itemCode,
                                                            subItemCode: this.state.subItemCode
                                                        }}
                                                        damageCode={this.state.damageCode}
                                                        severityCode={this.state.severityCode}
                                                        groupCode={this.props.account.groupCode}
                                                        categoryCode={this.props.consignment.categoryCode}
                                                        auctionCode={this.props.workOrderInfo.auctionCode}
                                                        setStatusMessage={this.setStatusMessage}
                                                        handleShowEstimateError={this.handleShowEstimateError}
                                                        disabled={true}

                                        /></div>
                                    {!this.state.validation.chargeableIsValid &&
                                        <div className="mt-8 invalid-feedback" style={{display: 'block'}}><span><i
                                            className="icon prism-icon-notification-circle text-danger"/> Required</span>
                                        </div>}
                                </Form.Group>
                            </Col>
                        </Row>

                        {this.state.showPricing &&
                            <SlidePanel isOpen={this.state.showPricing} width={"100vw"} zIndex={20000} showHeader={true}
                                        contentClassName="bg-white" from="right">
                                <Pricing hidePricingOverlay={this.hidePricingOverlay}
                                         id="pricing-overlay"
                                         damage={pricingUtils.buildDamage(this.state, pricingUtils.getDamageKey(this.state))}
                                         pricingTypes={this.state.pricingTypes}
                                         handlePricingUpdate={this.handlePricingUpdate}
                                         cancelPricingOverlay={this.cancelPricingOverlay}
                                />
                            </SlidePanel>}

                        {isCRSO &&
                            <DamagePricingInputs
                                data={this.state}
                                damage={this.props.damage}
                                toggle={this.props.toggle}
                                handleUpdatePricing={this.handleUpdatePricing}
                                handleManualUpdatePricing={this.handleManualUpdatePricing}
                                priceChange={this.handlePriceChange}
                                handleInputValidation={this.handleInputValidation}
                            />
                        }
                        {isCRSO &&
                            <Row>
                                <Col className="col-12 font-weight-bold">
                                    <TextCounter
                                        label="Part Number/Description"
                                        rows="1"
                                        id='part-description'
                                        onChange={this.handleEditPartDescriptionChange}
                                        value={this.state.partDescription}
                                        maxLength={30}
                                        disabled={this.state.disabled}
                                    />
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col className="col-12 font-weight-bold py-4">
                                <TextCounter
                                    id='damage-comments'
                                    onChange={this.handleNotesChange}
                                    placeholder="Enter Comments"
                                    label="Damage Comments"
                                    maxLength={30}
                                    rows="1"
                                    value={this.state.notes}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <OutlinePanel className="p-2 mb-4">
                                    <TakePhoto
                                        hasPhoto={this.props.hasActiveImage(
                                            this.state.damageKey
                                        )}
                                        photoIsRequired={this.photoIsRequired()}
                                        damage={this.state}
                                        damageKey={this.state.damageKey}
                                    />
                                </OutlinePanel>
                            </Col>
                        </Row>
                        {isCRSO && this.state.showPricingInputError ?
                            <Message id="missing-prices-message" color="danger"><i
                                className="icon prism-icon-notification-circle" color="danger"/>
                                {this.state.showEstimateError ? MISSING_ESTIMATE_MESSAGE : this.state.showPricingInputError ? MISSING_PRICING_MESSAGE : ''}
                            </Message>
                            : <></>}
                        <Row className="pb-4">
                            <Col xs={6} className="pr-2">
                                <Button id="auto-damage-cancel" className="w-100" outline
                                        onClick={this.props.onCancelClick}>Cancel</Button>
                            </Col>
                            <Col xs={6} className="pl-2">
                                <Button id="auto-damage-save"
                                        className="w-100"
                                        color="secondary"
                                        disabled={this.saveButtonDisabled()}
                                        onClick={this.handleSaveDamage}>Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const matchDispatchToProps = {
    addAutoDamage,
    hasActiveImage,
    showExteriorFlatCarView,
    addDamage
};

function mapStateToProps(
    {
        globalDisplay,
        account,
        consignment,
        workOrderInfo,
        damageImages,
        condition,
        designatedDescription,
        unit
    }
) {
    return {
        globalDisplay,
        account,
        consignment,
        workOrderInfo,
        damageImages,
        condition,
        designatedDescription,
        unit
    };
}

export default connect(mapStateToProps, matchDispatchToProps)(DamageModalDetails);